<template>
  <div class="flex-col">
    <div class="flex justify-between max-sm:justify-start max-sm:flex-col max-lg:px-3 lg:my-6 lg:mx-9">
      <a href="" class="inline-block max-sm:px-5">
        <img src="/images/user/logo/logo.png" alt="Logo">
      </a>
      <div class="flex max-sm:flex-col lg:items-center max-sm:mb-4">
        <h3 class="uppercase text-base font-semibold lg:mr-4 max-sm:px-6 max-sm:mb-3">Theo dõi chúng tôi</h3>
        <div class="flex lg:justify-center items-center max-sm:px-6">
          <a class="text-textBlackSecondary inline-block w-7 h-7 bg-[#EAEAEA] rounded-full mx-2" href="">
            <img src="/images/home/icon-fb.svg" class="mt-2 m-auto">
          </a>
          <a class="text-textBlackSecondary inline-block w-7 h-7 bg-[#EAEAEA] rounded-full mx-2" href="">
            <img src="/images/home/icon-twiter.svg" class="mt-2 m-auto">
          </a>
          <a class="text-textBlackSecondary inline-block w-7 h-7 bg-[#EAEAEA] rounded-full mx-2" href="">
            <img src="/images/home/icon-yt.svg" class="mt-2 m-auto">
          </a>
        </div>
      </div>
    </div>
    <div
      class="flex flex-auto lg:flex-row w-full bg-white border-y border-[#E4E4E4] md:flex-row max-sm:flex-col max-lg:px-4 p-6"
    >
      <div class="flex-col gap-4 flex w-1/3 max-sm:w-full max-sm:px-6 max-sm:mb-4">
        <h3 class="uppercase text-base font-semibold text-[#333]">Thông tin VNTF</h3>
        <ul class="text-sm text-textBlackSecondary">
          <li v-if="isShowTermPage" class="lg:mb-6 mb-4 flex justify-start items-center">
            <a :href="route('terms')" class="text-textBlackSecondary">{{ $t('Điều khoản') }}</a>
          </li>
          <li v-if="isShowLegalPage" class="lg:mb-6 mb-4 flex justify-start items-start">
            <a :href="route('legals')" class="text-textBlackSecondary">{{ $t('Pháp lý') }}</a>
          </li>
          <li v-if="isShowGuildPage" class="flex justify-start items-start">
            <a :href="route('guilds')" class="text-textBlackSecondary">{{ $t('Hướng dẫn sử dụng VNTF') }}</a>
          </li>
        </ul>
      </div>
      <div class="flex-col gap-4 flex w-1/3 max-sm:w-full max-sm:px-6 max-sm:mb-4">
        <h3 class="uppercase text-base font-semibold text-[#333]">Thông tin hữu ích</h3>
        <ul class="text-sm text-textBlackSecondary">
          <li class="lg:mb-6 mb-4 flex justify-start items-center">
            <a
              class="text-textBlackSecondary"
              :href="route('policy-and-procedures.MarketSelections', { tabActive: 3 })"
            >
              {{ $t('Lựa chọn thị trường') }}
            </a>
          </li>
          <li class="flex justify-start items-start">
            <a
              class="text-textBlackSecondary"
              :href="route('policy-and-procedures.MarketSelections', { tabActive: 3, activeCategory: 2 })"
            >
            {{ $t('Danh bạ cần thiết') }}
            </a>
          </li>
        </ul>
      </div>
      <div class="flex-col gap-4 flex w-1/3 max-sm:w-full max-sm:px-6">
        <h3 class="uppercase text-base font-semibold text-[#333]">Thông tin liên hệ</h3>
        <ul class="text-sm text-textBlackSecondary">
          <li class="lg:mb-6 mb-4 flex justify-start items-center">
            <i class="ri-map-pin-fill mr-[5px]" />
            <a class="text-textBlackSecondary" href="">{{ $t('Số 9, Đào Duy Anh, Đống Đa, Hà Nội') }}</a>
          </li>
          <li class="lg:mb-6 mb-4 flex justify-start items-start">
            <div class="inline-flex justify-start items-center">
              <i class="ri-phone-fill mr-[5px]" />
            </div>
            <a class="text-textBlackSecondary" href="">{{ $t('024.3577.1459') }}</a>
          </li>
          <li class="flex justify-start items-start">
            <div class="inline-flex justify-start items-center">
              <i class="ri-mail-fill mr-[5px]" />
            </div>
            <a class="text-textBlackSecondary" href="">{{ $t('banphapche@vcci.com.vn') }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-full px-5 bg-[#F5F5F5] py-7">
      <p class="text-[13px] text-bluePrimary">Bản quyền © thuộc Liên đoàn Thương mại và Công nghiệp Việt Nam (VCCI). Thiết kế website bởi <span class="text-[#F49D2E] text-[13px]">Rabiloo</span></p>
      <p class="text-[13px] text-bluePrimary">Khi trích dẫn hoặc đăng lại nội dung từ website này, đề nghị nêu rõ nguồn <span class="text-[#F49D2E] underline text-[13px]">thuanloithuongmai.vcci.com.vn</span> và giữ chính xác nội dung gốc, bao gồm cả tác giả, thời gian, địa điểm, sự kiện (nếu có).</p>
    </div>
  </div>
</template>
<script>
import axios from '@/plugins/axios'
export default {
  data() {
    return {
      isShowTermPage: false,
      isShowLegalPage: false,
      isShowGuildPage: false,
    }
  },
  created() {
    this.getDisplaySetting()
  },
  methods: {
    goToPolicyAndProcedurePage() {
      this.$inertia.visit(this.route('policy-and-procedures.MarketSelections', { tabActive: 3 }))
    },
    goToContactPostPage() {
        this.$inertia.visit(this.route('policy-and-procedures.MarketSelections', { tabActive: 3 , activeCategory: 2}))
    },
    async getDisplaySetting() {
      const { status, data } = await axios.get('get-setting-config')
      if (status === 200) {
        this.isShowTermPage = data?.data?.termIsDisplay,
        this.isShowLegalPage = data?.data?.legalIsDisplay,
        this.isShowGuildPage = data?.data?.guildIsDisplay
      }
    },
  },
}
</script>
